import React, { Fragment, Component } from "react";
import { connetParams } from "../utils/Connect";
import { getCookie, eraseCookie } from "../utils/Cookies";
import BarCharts from "./charts/barChart";
import DoughnutChart from "./charts/doughnutChart";
import RadarChart from "./charts/radarChart";
import Im from "./charts/customer/im";
import Os from "./charts/customer/os";
import Cp from "./charts/customer/cp";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ccr_like: 0,
            ccr_dont_like: 0,
            ei_like: 0,
            ei_dont_like: 0,
            cas_like: 0,
            cas_dont_like: 0,
            ifd_like: 0,
            ifd_dont_like: 0,
            cgi_dont_like: 0,
            cgi_like: 0,
            likes: 0,
            dont_likes: 0,
            assistants_data: [],
            radar_data: false,
            se: [],
            amoc: [],
            user_type: false
        }
    }
    componentDidMount() {
        if (getCookie("type")) {
            this.setState({
                user_type: getCookie("type")
            })
            if (getCookie("type") === 'admin') {
                let myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + getCookie("token"));
                const params = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };
                connetParams(
                    "dashboard",
                    params
                )
                    .then((response) => response.text())
                    .then((result) => {
                        const data = JSON.parse(result);
                        if (data["detail"] === "Invalid credentials") {
                            eraseCookie("token");
                            this.props.navigate('/');
                        }
                        this.setState({
                            ccr_like: data.ccr.likes,
                            ccr_dont_like: data.ccr.dont_likes,
                            ei_like: data.ei.like,
                            ei_dont_like: data.ei.dont_likes,
                            cas_like: data.cas.likes,
                            cas_dont_like: data.cas.dont_likes,
                            ifd_like: data.ifd.likes,
                            ifd_dont_like: data.ifd.dont_likes,
                            cgi_like: data.cgi.likes,
                            cgi_dont_like: data.cgi.dont_likes,
                            likes: data.total_likes,
                            dont_likes: data.total_dont_likes,
                            messages: data.total_messages,
                            assistants_data: data.assistants_data,
                            loading: false
                        });
                    })
                    .catch((error) => {
                        console.log(error.message);
                        console.error(
                            "There was a problem with the fetch operation:",
                            error.message
                        );
                        eraseCookie("token");
                    });
            }
            else if (getCookie("type") === 'customer') {
                let myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + getCookie("token"));
                const params = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };
                connetParams(
                    "chart",
                    params
                )
                    .then((response) => response.text())
                    .then((result) => {
                        const data = JSON.parse(result);
                        if (data["detail"] === "Invalid credentials") {
                            eraseCookie("token");
                            this.props.navigate('/');
                        }
                        this.setState({
                            se: data.se,
                            amoc: data.amoc
                        });
                    })
                    .catch((error) => {
                        console.log(error.message);
                        console.error(
                            "There was a problem with the fetch operation:",
                            error.message
                        );
                        eraseCookie("token");
                    });
            }
        }
    }
    render() {
        const {
            ccr_like,
            ccr_dont_like,
            ei_dont_like,
            ei_like,
            cas_like,
            cas_dont_like,
            ifd_like,
            ifd_dont_like,
            cgi_like,
            cgi_dont_like,
            likes,
            dont_likes,
            assistants_data,
            radar_data,
            se,
            amoc,
            user_type
        } = this.state;
        console.log(amoc);
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Dashboard</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item">Dashboard</li>
                        </ol>
                        {
                            !user_type ?
                                <div className="d-grid gap-2 col-12 mx-auto">
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-grow text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    {
                                        user_type === 'admin' ?
                                            <div>
                                                <div className="card mb-4">
                                                    <div className="card-header mb-3">
                                                        <i className="fas fa-chart-column me-1"></i>
                                                        Me gusta y No me gusta por categoria
                                                    </div>

                                                    <div className="container">
                                                        <div className="row justify-content-center">
                                                            <BarCharts cgi_like={cgi_like}
                                                                ccr_like={ccr_like}
                                                                ccr_dont_like={ccr_dont_like}
                                                                ei_like={ei_like}
                                                                ei_dont_like={ei_dont_like}
                                                                cas_like={cas_like}
                                                                cas_dont_like={cas_dont_like}
                                                                ifd_like={ifd_like}
                                                                ifd_dont_like={ifd_dont_like}
                                                                cgi_dont_like={cgi_dont_like}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="card mb-4">
                                                            <div className="card-header mb-3">
                                                                <i className="fas fa-chart-pie me-1"></i>
                                                                Me gusta y No me gusta
                                                            </div>
                                                            <DoughnutChart likes={likes} dont_likes={dont_likes} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <i className="fas fa-chart-line me-1"></i>
                                                                Asistentes
                                                            </div>
                                                            <div className="container">
                                                                <div className="row justify-content-center">
                                                                    <div className="col-11 m-2">
                                                                        <select onChange={e => this.setState({ radar_data: e.target.value })} className="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                                                                            <option selected>Selecciona uno..</option>
                                                                            {
                                                                                assistants_data.map(assistant_data => {
                                                                                    return <option value={assistant_data.id}>{assistant_data.name}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    <RadarChart id={radar_data} data={assistants_data} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="card mb-4">
                                                            <div className="card-header mb-3">
                                                                <i className="fas fa-chart-pie me-1"></i>
                                                                Motivadores para la Conversión
                                                            </div>
                                                            <Im data={amoc.im} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <i className="fas fa-chart-pie me-1"></i>
                                                                Obstáculos para la Conversión
                                                            </div>
                                                            <Os data={amoc.io} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="card mb-4">
                                                            <div className="card-header mb-3">
                                                                <i className="fas fa-chart-pie me-1"></i>
                                                                Conocimiento previo de la empresa
                                                            </div>
                                                            <Cp data={se.cpe}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <i className="fas fa-chart-pie me-1"></i>
                                                                Conocimiento previo del producto
                                                            </div>
                                                            <Cp data={se.cpp}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <i className="fas fa-chart-pie me-1"></i>
                                                                Entendimiento de la propuesta de valor
                                                            </div>
                                                            <Cp data={se.epv}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="card mb-4">
                                                    <div className="card-header mb-3">
                                                        <i className="fas fa-chart-column me-1"></i>
                                                        Análisis de Motivadores y Obstáculos para la Conversión
                                                    </div>

                                                    <div className="container">
                                                        <div className="row justify-content-center">
                                                            <AmocBar amoc={amoc} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card mb-4">
                                                    <div className="card-header mb-3">
                                                        <i className="fas fa-chart-column me-1"></i>
                                                        Satisfacción del Usuario
                                                    </div>

                                                    <div className="container">
                                                        <div className="row justify-content-center">
                                                            <SuRadar su={su} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                    }
                                </div>
                        }
                    </div>
                </main>
            </Fragment>
        )
    }
}

export default Index;