import React, { Fragment, Component } from "react";
import { useParams } from "react-router-dom";
import { connetParams, url } from "../../utils/Connect";
import { getCookie, eraseCookie } from "../../utils/Cookies";
import { Link, useNavigate } from "react-router-dom";
import { downloadExcel } from 'react-export-table-to-excel';


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: this.props.data.user_id,
            account_sid: this.props.data.account_sid,
            tableRef: this.props.tableRef,
            conversation: {},
            messages: [],
            loading: true
        };
        this.handleDownloadExcel = this.handleDownloadExcel.bind(this)
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie("token"));
        const params = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        connetParams(
            "conversation/" + this.state.user_id + "/" + this.state.account_sid,
            params
        )
            .then((response) => response.text())
            .then((result) => {
                const data = JSON.parse(result);
                if (data["detail"] === "Invalid credentials") {
                    eraseCookie("token");
                    this.props.navigate('/');
                }
                this.setState({
                    conversation: data.conversation,
                    messages: data.messages,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error.message);
                console.error(
                    "There was a problem with the fetch operation:",
                    error.message
                );
                eraseCookie("token");
            });
    }
    handleDownloadExcel() {
        const messages = this.state.messages;
        const conversation = this.state.conversation;
        const header = ['Rol', 'Contenido', 'Valoracion', 'Comentario'];
        const body = messages.map(message => {
            return {
                role: message.role,
                content: message.content,
                rate: message.rate ? message.rate : 'None',
                comment: message.comment ? message.comment : 'None'
            }
        });
        downloadExcel({
            fileName: conversation.thread_id,
            sheet: "conversation",
            tablePayload: {
                header: header,
                // accept two different data structures
                body: body,
            },
        });
    }

    render() {
        const { conversation, messages, tableRef, loading } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Conversation</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item">
                                <Link to={"/"}>Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item ">
                                <Link to={"/conversations"}>Conversaciones</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                {conversation.thread_id}
                            </li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                {
                                    loading ? (
                                        <div className="d-grid gap-2 col-12 mx-auto">
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="container">
                                            <div className="row">
                                                <div className="col col-sm-3">
                                                    <h3 className="mt-4">Usuario:</h3>
                                                    <p>{conversation.user_id}</p>
                                                </div>
                                                <div className="col col-sm-3">
                                                    <h3 className="mt-4">Ticket:</h3>
                                                    <p>{conversation.account_sid}</p>
                                                </div>
                                                <div className="col col-sm-3">
                                                    <h3 className="mt-4">Asistentes:</h3>
                                                    <p>{conversation.director_id}</p>
                                                </div>
                                                <div className="col col-sm-3">
                                                    <h3 className="mt-4">Tipo:</h3>
                                                    <p>{conversation.type}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Mensages
                            </div>
                            {
                                loading ? (
                                    <div className="d-grid gap-2 col-12 mx-auto">
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-body">
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <a
                                                className="btn btn-primary me-md-2"
                                                href={url + 'conversation/download/' + conversation.thread_id}
                                                download
                                            >
                                                <i className="fa-solid fa-file-arrow-down"></i>
                                            </a>
                                        </div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Rol</th>
                                                    <th scope="col">Contenido</th>
                                                    <th scope="col">Valoracion</th>
                                                    <th scope="col">Comentario</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    messages.map(message => {
                                                        return (
                                                            <tr>
                                                                <td>{message.role}</td>
                                                                <td style={{ 'width': '200px' }}><p style={{ 'text-overflow': 'ellipsis', 'white-space': 'nowrap', 'overflow': 'hidden', 'max-width': '200px' }}>{message.content}</p></td>
                                                                <td><p style={{ 'text-align': 'center' }}>{message.rate === 'like' ? <i className="fa-solid fa-heart"></i> : ''}{message.rate === 'dont_like' ? <i className="fa-solid fa-heart-crack"></i> : ''}{!message.rate ? <i className="fa-solid fa-circle-xmark"></i> : ''}</p></td>
                                                                <td style={{ 'width': '200px' }}><p style={{ 'text-overflow': 'ellipsis', 'white-space': 'nowrap', 'overflow': 'hidden', 'max-width': '200px' }}>{message.comment ? message.comment : <i className="fa-solid fa-circle-xmark"></i>}</p></td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </main>
            </Fragment>
        );
    }
}

function Conversation() {
    let navigate = useNavigate();
    return <Element data={useParams()} navigate={navigate} />;
}

export default Conversation;
