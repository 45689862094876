import React, { Fragment, Component } from "react";

class EditAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sid: '',
            auth_token: '',
            assistant_id: '',
            assistants: Array(),
            code: '',
            loading: false
        }
    }
    render() {
        return(
            <Fragment>
            </Fragment>
        )
    }
}

export default EditAccount;