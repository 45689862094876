import React, { Fragment, Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { eraseCookie } from '../../utils/Cookies';

class Element extends Component {
    constructor(props) {
        super(props);
    }
    logout = () => {
        eraseCookie('name');
        eraseCookie('type');
        eraseCookie('token');
        this.props.navigate('/');
    }
    render() {
        return (
            <Fragment>
                <nav className="sb-topnav navbar navbar-expand navbar-dark bg-primary">
                    <Link className="navbar-brand ps-3" to={"/"}><img src={window.location.origin + "/img/icon_White.png"} alt="" width="50" height="50" style={{'margin': '5px'}}/>empatIA</Link>
                    <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i className="fas fa-bars"></i></button>
                    <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                        <div className="input-group">
                        </div>
                    </form>
                    <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to={"/settings"}>Settings</Link></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="" onClick={this.logout}>Logout</a></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </Fragment>
        )
    }
}

function Navbar(){
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default Navbar;