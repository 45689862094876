import React, { Fragment, Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie, setCookie } from '../../utils/Cookies';

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: Array()
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'user/data',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.setState({
                    user: data
                })
                setCookie('type', data.type, 1);
            })
            .catch(error => {
                console.log(error.message);
                console.error('There was a problem with the fetch operation:', error.message);
                eraseCookie('token');
                this.props.navigate('/');
            });
    }
    render() {
        const { user } = this.state;
        return (
            <Fragment>
                <div id="layoutSidenav_nav">
                    <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
                        <div className="sb-sidenav-menu">
                            <div className="nav">
                                {/* <div className="sb-sidenav-menu-heading">Core</div> */}
                                <Link className="nav-link" to={"/"}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                    Dashboard
                                </Link>
                                {
                                    user.type == 'admin' ?
                                        <div>
                                            <Link className="nav-link" to={"/super_assistant"}>
                                                <div className="sb-nav-link-icon"><i className="fa-solid fa-users-rays"></i></div>
                                                Asistentes
                                            </Link>
                                            <Link className="nav-link" to={"/conversations"}>
                                                <div className="sb-nav-link-icon"><i className="fas fa-comments"></i></div>
                                                Conversaciones
                                            </Link>
                                            <Link className="nav-link" to={"/customers"}>
                                                <div className="sb-nav-link-icon"><i className="fas fa-users"></i></div>
                                                Clientes
                                            </Link>
                                            <Link className="nav-link" to={"/users"}>
                                                <div className="sb-nav-link-icon"><i className="fas fa-user-group"></i></div>
                                                Usuarios
                                            </Link>
                                            <Link className="nav-link" to={"/whatsapp"}>
                                                <div className="sb-nav-link-icon"><i className="fa-brands fa-whatsapp"></i></div>
                                                Whatsapp
                                            </Link>
                                            <Link className="nav-link" to={"/feedback"}>
                                                <div className="sb-nav-link-icon"><i className="fa-solid fa-comment-dots"></i></div>
                                                Feedback
                                            </Link>
                                        </div>
                                        :
                                        ''
                                }
                            </div>
                        </div>
                        <div className="sb-sidenav-footer">
                            <div className="small">Logged in as:</div>
                            {user.name}
                        </div>
                    </nav>
                </div>
            </Fragment>
        )
    }
}

function SideNav() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default SideNav;