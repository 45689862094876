import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            old_password: '',
            confirm_old_password: '',
            name: '',
            email: '',
            confirm_password: '',
            password: '',
            type: '',
            customer_id: ''
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'user',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.setState({
                    name: data.name,
                    old_password: data.password,
                    email: data.email,
                    type: data.type,
                    customer_id: data.customer_id,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error.message);
                console.error('There was a problem with the fetch operation:', error.message);
                eraseCookie('token');
            });
    }
    updateUser = () => {
        this.setState({ loading: true })
        console.log(this.props.auth);
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const raw = JSON.stringify({
            "name": this.state.name,
            'email': this.state.email,
            "type": this.state.type,
            "customer_id": this.state.customer_id,
            "password": this.state.password === '' ? this.state.old_password : this.state.password
        });

        let payload = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'user',
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.props.navigate('/settings');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const {
            loading,
            confirm_password,
            password,
            name,
            email,
            old_password,
            confirm_old_password,
            type,
        } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Settings</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Settings</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">Nombre:</label>
                                        <input type="text" value={name} onChange={e => this.setState({ name: e.target.value })} className="form-control" id="name" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="email" className="col-form-label">Correo:</label>
                                        <input type="text" value={email} onChange={e => this.setState({ email: e.target.value })} className="form-control" id="email" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="old_password" className="col-form-label">Actual Contraseña:</label>
                                        <input type="password" onChange={e => this.setState({ confirm_old_password: e.target.value })} className="form-control" id="old_password" />
                                    </div>
                                    {confirm_old_password !== '' && confirm_old_password !== old_password ? <div className="alert alert-danger" role="alert">
                                        No es la contraseña actual
                                    </div> : ''}
                                    {
                                        confirm_old_password === '' || confirm_old_password !== old_password ?
                                            <div>
                                                <div className="mb-3">
                                                    <label for="password" className="col-form-label">Contraseña:</label>
                                                    <input type="password" disabled className="form-control" id="password" />
                                                </div>
                                                <div className="mb-3">
                                                    <label for="confirm_password" className="col-form-label">Confirmar Contraseña:</label>
                                                    <input type="password" disabled className="form-control" id="auth_intercom" />
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className="mb-3">
                                                    <label for="password" className="col-form-label">Contraseña:</label>
                                                    <input type="password" onChange={e => this.setState({ password: e.target.value })} className="form-control" id="password" />
                                                </div>
                                                <div className="mb-3">
                                                    <label for="confirm_password" className="col-form-label">Confirmar Contraseña:</label>
                                                    <input type="password" onChange={e => this.setState({ confirm_password: e.target.value })} className="form-control" id="auth_intercom" />
                                                </div>
                                                {confirm_password !== '' && confirm_password !== password ? <div className="alert alert-danger" role="alert">
                                                    Las contraseñas no coinciden
                                                </div> : ''}
                                            </div>
                                    }
                                    <div className="mb-3">
                                        <label for="type" className="col-form-label">tipo:</label>
                                        <select className="form-control" value={type} disabled id="type" onChange={e => this.setState({ type: e.target.value })} aria-label="Default select example">
                                            <option selected>Seleccione uno</option>
                                            <option value="admin">Admin</option>
                                            <option value="customer">Cliente</option>
                                        </select>
                                    </div>
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        confirm_old_password !== '' && confirm_old_password !== old_password ?
                                            <button className="btn btn-primary disabled" onClick={this.createUser} type="button">Guardar</button>
                                            :
                                            loading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="spinner-grow text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <button className="btn btn-primary" onClick={this.updateUser} type="button">Guardar</button>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

function Settings() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default Settings;