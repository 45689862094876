import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            assistant_id: '',
            loading: false
        }
    }
    createAssistant = () => {
        this.setState({ loading: true })
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const raw = JSON.stringify({
            "name": this.state.name,
            "description": this.state.description,
            'assistant_id': this.state.assistant_id
        });

        let payload = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'super_assistant/associate',
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.props.navigate('/super_assistant');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const { loading } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Asistentes</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/super_assistant"}>Super asistentes</Link></li>
                            <li className="breadcrumb-item active">Asociar asistente</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">Nombre:</label>
                                        <input type="text" onChange={e => this.setState({ name: e.target.value })} className="form-control" id="name" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="description" className="col-form-label">Descripcion:</label>
                                        <textarea className="form-control" onChange={e => this.setState({ description: e.target.value })} id="description"></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label for="model" className="col-form-label">Id del asistente:</label>
                                        <input type="text" onChange={e => this.setState({ assistant_id: e.target.value })} className="form-control" id="model" />
                                    </div>
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button className="btn btn-primary" onClick={this.createAssistant} type="button">Guardar</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function AssociateAssistant() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default AssociateAssistant;