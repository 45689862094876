import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: Array(),
            page: 1,
            loading: true
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'customers?page=' + this.state.page,
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    window.location.reload();
                }
                this.setState({
                    customers: data,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error.message);
                console.error('There was a problem with the fetch operation:', error.message);
                eraseCookie('token');
            });
    }
    delete = (id) => {
        if (window.confirm('Estas seguro que quieres eliminar a este elemento?')) {
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + getCookie('token'));
            const params = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };
            connetParams(
                'customer/' + id,
                params
            ).then(response => response.text())
                .then(result => {
                    const data = JSON.parse(result);
                    if (data['detail'] === 'Invalid credentials') {
                        eraseCookie('token');
                        this.props.navigate('/customers');
                    }
                    this.componentDidMount();
                })
                .catch(error => {
                    console.log(error.message);
                    console.error('There was a problem with the fetch operation:', error.message);
                    eraseCookie('token');
                });
        } else {
            // Do nothing!
            console.log('cancel.');
        }
    }
    next = () => {
        const page = this.state.page;
        this.setState({
            page: page + 1,
            loading: true
        });
        this.componentDidMount();
    }
    back = () => {
        const page = this.state.page;
        if (page - 1 > 0) {
            this.setState({
                page: page - 1,
                loading: true
            });
            this.componentDidMount();
        }
    }
    render() {
        const { customers, loading, page } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Clientes</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Clientes</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Lista de clienes
                            </div>
                            <div className="card-body">
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <Link
                                        to={'/customers/create'}
                                        className="btn btn-primary me-md-2"
                                    >
                                        Crear Cliente
                                    </Link>
                                </div>
                                {
                                    loading ? (
                                        <div className="d-grid gap-2 col-12 mx-auto">
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">id</th>
                                                    <th scope="col">name</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    customers.map(customer => {
                                                        return (
                                                            <tr>
                                                                <td>{customer.id}</td>
                                                                <td>{customer.name}</td>
                                                                <td>
                                                                    <Link
                                                                        to={'/customer/' + customer.id}
                                                                    >
                                                                        <i className="fa-solid fa-pen-to-square m-1"></i>
                                                                    </Link>
                                                                    <a className="link-danger" type="submit" onClick={() => this.delete(customer.id)}><i className="fa-solid fa-trash m-1"></i></a >
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>

                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        {
                                                            page <= 1 ? (
                                                                <a className="page-link disabled" aria-label="Previous">
                                                                    <span aria-hidden="true">&laquo;</span>
                                                                </a>
                                                            ) :
                                                                (

                                                                    <a className="page-link" onClick={this.back} aria-label="Previous">
                                                                        <span aria-hidden="true">&laquo;</span>
                                                                    </a>
                                                                )
                                                        }
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" onClick={this.next} aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </table>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

function Customers() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default Customers;