import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            id: '',
            auth_intercom: '',
            assistant_id: '',
            loading: false,
            // personal_emails: '',
            // personal_ids: '',
            assistants: Array()
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'customer/' + this.props.data.customer_id,
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.setState({
                    name: data.name,
                    auth_intercom: data.auth_intercom,
                    id: data.id,
                    assistant_id: data.assistant_id,
                    // personal_emails: data.personal_emails,
                    // personal_ids: data.personal_ids,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error.message);
                console.error('There was a problem with the fetch operation:', error.message);
                eraseCookie('token');
            });
        let newHeaders = new Headers();
        newHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const param = {
            method: 'GET',
            headers: newHeaders,
            redirect: 'follow'
        };
        connetParams(
            'super_assistant',
            param
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.setState({
                    assistants: data
                })
            })
            .catch(error => {
                console.log(error.message);
                console.error('There was a problem with the fetch operation:', error.message);
                eraseCookie('token');
            });
    }
    UpdateCustomer = () => {
        this.setState({ loading: true })
        console.log(this.props.auth);
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const raw = JSON.stringify({
            "id": this.state.id,
            "name": this.state.name,
            'auth_intercom': this.state.auth_intercom,
            'assistant_id': this.state.assistant_id
        });

        let payload = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'customer',
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.props.navigate('/customers');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const { loading, name, auth_intercom, assistants, assistant_id } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Editar clientes</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/customers"}>Clientes</Link></li>
                            <li className="breadcrumb-item active">{name}</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">Nombre:</label>
                                        <input type="text" value={name} onChange={e => this.setState({ name: e.target.value })} className="form-control" id="name" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="auth_intercom" className="col-form-label">Auth Intercom:</label>
                                        <input type="text" value={auth_intercom} onChange={e => this.setState({ auth_intercom: e.target.value })} className="form-control" id="auth_intercom" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="assistants" className="col-form-label">Asistente:</label>
                                        <select className="form-control" value={assistant_id} id="assistants" onChange={e => this.setState({ assistant_id: e.target.value })}>
                                            <option selected>Seleccione uno</option>
                                            {
                                                assistants.map(assistant => {
                                                    return <option value={assistant.id}>{assistant.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {/* <div className="mb-3 form-check">
                                                <input className="form-check-input" type="checkbox" value={personal_ids} onChange={e => this.setState({ personal_ids: e.target.value })} id="personal_ids"/>
                                                <label for="personal_ids" className="form-check-label">Bloquear identificacion personal</label>
                                            </div>
                                            <div className="mb-3 form-check">
                                                <input className="form-check-input" type="checkbox" value={personal_emails} onChange={e => this.setState({ personal_emails: e.target.value })} id="personal_emails"/>
                                                <label for="personal_emails" className="form-check-label">Bloquear email personal</label>
                                            </div> */}
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button className="btn btn-primary" onClick={this.UpdateCustomer} type="button">Guardar</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function EditCustomer() {
    let navigate = useNavigate();
    return <Element data={useParams()} navigate={navigate} />
}

export default EditCustomer;