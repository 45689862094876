import React, { Fragment, Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

class io extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let io = this.props.data;
        let uo = io != undefined ? io.uo : 0;
        let pi = io != undefined ? io.pi : 0;
        let lf = io != undefined ? io.lf : 0;
        let is = io != undefined ? io.is : 0;
        let ids = io != undefined ? io.ids : 0;
        let ao = io != undefined ? io.ao : 0;
        let ps = io != undefined ? io.ps : 0;
        let mec = io != undefined ? io.mec : 0;
        let cc = io != undefined ? io.mec : 0;
        const data = {
            labels: [
                'Usabilidad Compleja', 
                'Problemas de Integración', 
                'Limitaciones de Funcionalidades', 
                'Insuficiencia de Soporte',
                'Inestabilidad del Sistema', 
                'Alto Costo',
                'Preocupaciones de Seguridad', 
                'Mala Experiencia del Cliente', 
                'Comparación con la competencia'
            ],
            datasets: [
                {
                    label: '%',
                    data: [
                        uo,
                        pi,
                        lf,
                        is,
                        ids,
                        ao,
                        ps,
                        mec,
                        cc
                    ],
                    backgroundColor: [
                        '#2f3559',
                        '#9a5071',
                        '#e394a7',
                        '#f1bbbb',
                        '#e6d8cb',
                        '#940533',
                        '#c0012a',
                        '#ff8800'
                    ],
                    borderWidth: 1,
                },
            ],
        };
        return <Doughnut data={data} />
    }
}

export default io;