import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            type: '',
            customer_id: '',
            password: '',
            confirm_password: '',
            customers: Array(),
            loading: false
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'customers',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.setState({
                    customers: data
                })
            })
            .catch(error => {
                console.log(error.message);
                console.error('There was a problem with the fetch operation:', error.message);
                eraseCookie('token');
            });
    }
    createUser = () => {
        this.setState({ loading: true })
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const raw = JSON.stringify({
            "name": this.state.name,
            "email": this.state.email,
            "type": this.state.type,
            "customer_id": this.state.customer_id,
            "password": this.state.password
        });

        let payload = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'register',
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.props.navigate('/users');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const { customers, loading, password, confirm_password, type } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Crear Usuario</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/customers"}>Usuarios</Link></li>
                            <li className="breadcrumb-item active">Crear usuarios</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">Nombre:</label>
                                        <input type="text" onChange={e => this.setState({ name: e.target.value })} className="form-control" id="name" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="email" className="col-form-label">Correo:</label>
                                        <input type="text" onChange={e => this.setState({ email: e.target.value })} className="form-control" id="email" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="password" className="col-form-label">Contraseña:</label>
                                        <input type="password" onChange={e => this.setState({ password: e.target.value })} className="form-control" id="password" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="confirm_password" className="col-form-label">Contraseña:</label>
                                        <input type="password" onChange={e => this.setState({ confirm_password: e.target.value })} className="form-control" id="auth_intercom" />
                                    </div>
                                    {confirm_password !== '' && confirm_password !== password ? <div className="alert alert-danger" role="alert">
                                        Las contraseñas no coinciden
                                    </div> : ''}
                                    <div className="mb-3">
                                        <label for="type" className="col-form-label">tipo:</label>
                                        <select className="form-control" id="type" onChange={e => this.setState({ type: e.target.value })} aria-label="Default select example">
                                            <option selected>Seleccione uno</option>
                                            <option value="admin">Admin</option>
                                            <option value="customer">Cliente</option>
                                        </select>
                                    </div>
                                    {type !== 'customer' ?
                                        <div className="mb-3">
                                            <label for="customer_id" className="col-form-label">Cliente:</label>
                                            <select className='form-control' disabled id="customer_id" onChange={e => this.setState({ type: e.target.value })} aria-label="Default select example">
                                                <option selected>Seleccione uno</option>
                                            </select>
                                        </div>
                                        :
                                        <div className="mb-3">
                                            <label for="customer_id" className="col-form-label">Cliente:</label>
                                            <select className='form-control' id="customer_id" onChange={e => this.setState({ customer_id: e.target.value })} aria-label="Default select example">
                                                <option selected>Seleccione uno</option>
                                                {
                                                    customers.map(customer => {
                                                        return <option value={customer.id}>{customer.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button className="btn btn-primary" onClick={this.createUser} type="button">Guardar</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

function CreateUser() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default CreateUser;