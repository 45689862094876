import React, { Fragment, Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

class Cp extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let cp = this.props.data;
        let one = cp != undefined ? cp.one : 0;
        let two = cp != undefined ? cp.two : 0;
        let three = cp != undefined ? cp.three : 0;
        let four = cp != undefined ? cp.four : 0;
        let five = cp != undefined ? cp.five : 0;
        const data = {
            labels: ['1', '2', '3', '4', '5'],
            datasets: [
              {
                label: '# of Votes',
                data: [one, two, three, four, five],
                backgroundColor: [
                  '#7001ce',
                  '#8b1fda',
                  '#a73de7',
                  '#c25bf3',
                  '#dd79ff'
                ],
                borderWidth: 1,
              },
            ],
          };
          
        return <Pie data={data} />
    }
}

export default Cp;