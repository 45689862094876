export const url = 'https://api.ada-impacto.com/v1.1/';

// export const url = 'http://127.0.0.1:8000/v1.1/'

export function connect(endpoint){
    return fetch(url + endpoint)
}

export function connetParams(endpoint, params){
    return fetch(url + endpoint, params);
}

export function deleted(endpoint, params){
    return fetch(url + endpoint, params);
}

export function createAsistant(payload){
    return fetch(url + 'post_assistant', {  // Make sure the URL endpoint is correct for assistant
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
}