import { useParams } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect, Fragment } from "react";
import SideNav from './components/layouts/sideNav';
import Navbar from './components/layouts/navbar';
import Footer from './components/layouts/footer';
import Login from './components/Login';
import Index from './components/Index';
import SuperAssistants from './components/assistants';
import AssociateAssistant from './components/assistants/create';
import AssistantView from './components/assistants/view';
import { getCookie, setCookie, eraseCookie } from './utils/Cookies';
import Conversations from './components/conversations';
import Conversation from './components/conversations/view';
import Customers from './components/customers';
import CreateCustomer from './components/customers/create';
import CreateUser from './components/user/create';
import User from './components/user';
import Settings from './components/settings';
import Whatsapp from './components/whatsapp';
import AssociateAccount from './components/whatsapp/create';
import EditUser from './components/user/edit';
import EditAccount from './components/whatsapp/edit';
import EditCustomer from './components/customers/edit';
import Feedback from './components/feedback';
import './App.css';

function App() {
  const [token, setToken] = useState('');
  const auth = getCookie('token');
  if (!auth) {
    return <Login token={setToken} />;
  }
  else if (token === '') {
    setToken(auth);
  }

  return (
    <Router>
      <Fragment>
        <Navbar />
        <div id="layoutSidenav">
          <SideNav />
          <div id="layoutSidenav_content">
            <Routes>
              <>
                <Route path="/" element={<Index />} />
                <Route path="/super_assistant" element={<SuperAssistants auth={token} />} />
                <Route path="/super_assistant/associate" element={<AssociateAssistant auth={token} />} />
                <Route path="/super_assistant/:assistant_id" element={<AssistantView auth={token} />} />
                <Route path="/conversations" element={<Conversations auth={token} />} />
                <Route path="/conversation/:user_id/:account_sid" element={<Conversation auth={token} />} />
                <Route path="/customers" element={<Customers auth={token} />} />
                <Route path="/customers/create" element={<CreateCustomer auth={token} />} />
                <Route path="/customer/:customer_id" element={<EditCustomer auth={token} />} />
                <Route path="/users" element={<User auth={token} />} />
                <Route path="/users/create" element={<CreateUser auth={token} />} />
                <Route path="/user/:user_id" element={<EditUser auth={token} />} />
                <Route path="/settings" element={<Settings auth={token} />} />
                <Route path="/whatsapp" element={<Whatsapp auth={token} />} />
                <Route path="/whatsapp/create" element={<AssociateAccount auth={token} />} />
                <Route path="/whatsapp/:account_id" element={<EditAccount auth={token} />} />
                <Route path="/feedback" element={<Feedback auth={token} />} />
              </>
            </Routes>
            <Footer />
          </div>
        </div>
      </Fragment>
    </Router>
  );
}

export default App;
