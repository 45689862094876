import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { Link, redirect, useNavigate } from "react-router-dom";

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sid: '',
            auth_token: '',
            assistant_id: '',
            assistants: Array(),
            code: '',
            loading: false
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'super_assistant',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.setState({
                    assistants: data,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error.message);
                console.error('There was a problem with the fetch operation:', error.message);
                eraseCookie('token');
            });
    }
    associate = () => {
        this.setState({ loading: true })
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const raw = JSON.stringify({
            "sid": this.state.sid,
            "assistant_id": this.state.assistant_id,
            "auth_token": this.state.auth_token,
            "code": this.state.code
        });

        let payload = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'whatsapp/create',
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.props.navigate('/whatsapp');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const { loading, assistants } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Crear clientes</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/whatsapp"}>Whatsapp</Link></li>
                            <li className="breadcrumb-item active">Asociar Cuenta</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">SID:</label>
                                        <input type="text" onChange={e => this.setState({ sid: e.target.value })} className="form-control" id="name" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="auth_intercom" className="col-form-label">Auth Token:</label>
                                        <input type="text" onChange={e => this.setState({ auth_token: e.target.value })} className="form-control" id="auth_intercom" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="assistants" className="col-form-label">Asistente:</label>
                                        <select className="form-control" id="assistants" onChange={e => this.setState({ assistant_id: e.target.value })}>
                                            <option selected>Seleccione uno</option>
                                            {
                                                assistants.map(assistant => {
                                                    return <option value={assistant.id}>{assistant.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label for="code" className="col-form-label">Code:</label>
                                        <input type="text" onChange={e => this.setState({ code: e.target.value })} className="form-control" id="code" />
                                    </div>
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button className="btn btn-primary" onClick={this.associate} type="button">Guardar</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

function AssociateAccount() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default AssociateAccount;