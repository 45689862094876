import React, { Fragment, Component } from "react";
import { useParams } from 'react-router-dom';
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assistant_id: this.props.data.assistant_id,
            assistant: {},
            data: {}
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'super_assistant/' + this.state.assistant_id,
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.setState({
                    assistant: data.super_assistant,
                    data: data.data
                })
            })
            .catch(error => {
                console.log(error.message);
                console.error('There was a problem with the fetch operation:', error.message);
                eraseCookie('token');
            });
    }
    render() {
        const { assistant, data } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">{assistant.name}</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/super_assistant"}>Super asistentes</Link></li>
                            <li className="breadcrumb-item active">{assistant.name}</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <h3 className="mt-4">Descripcion:</h3>
                                <p>{assistant.description}</p>
                                <h3 className="mt-4">Instrucciones:</h3>
                                <p>{data.instructions}</p>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function AssistantView() {
    let navigate = useNavigate();
    return <Element data={useParams()} navigate={navigate} />
}

export default AssistantView;