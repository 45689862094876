import React, { Fragment, Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

class Im extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let im = this.props.data;
        let fs = im != undefined ? im.fs : 0;
        let ims = im != undefined ? im.im : 0;
        let ce = im != undefined ? im.ce : 0;
        let hga = im != undefined ? im.hga : 0;
        let sc = im != undefined ? im.sc : 0;
        const data = {
            labels: [
                'Facilidad de Uso', 
                'Integración y Multicanalidad', 
                'Costo-Efectividad', 
                'Herramientas de Gestión y Análisis', 
                'Soporte y Comunidad'
            ],
            datasets: [
                {
                    label: '%',
                    data: [fs, ims, ce, hga, sc],
                    backgroundColor: [
                        '#2f3559',
                        '#9a5071',
                        '#e394a7',
                        '#f1bbbb',
                        '#e6d8cb'
                    ],
                    borderWidth: 1,
                },
            ],
        };
        return <Doughnut data={data} />
    }
}

export default Im;